const appData = window.appData || {};

if (appData.userTypes) {

    for (let type in appData.userTypes) {
        if (appData.userTypes.hasOwnProperty(type)) {
            appData.userTypes[type].text = appData.locale.layout.fields[type];
        }
    }
}

if (appData.userTypesArray) {
    appData.userTypesArray.forEach(type => {
        type.text = appData.locale.layout.fields[type.label]
    });
}

if (appData.documentTypesArray) {
    appData.documentTypesArray.forEach(type => {
        type.text = appData.locale.layout.fields[type.label]
    });
}

if (appData.addressType) {
    appData.addressType.forEach(type => {

        console.log(appData.locale.layout.address[type.label])

        type.text = appData.locale.layout.address[type.label]
    });
}

export default appData;
